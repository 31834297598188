<template>
    <div class="content-page">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                        <div>
                            <h4 class="mb-3">Liste des Categories de produits</h4>
                            <p class="mb-0">Utilisez la liste des catégories pour décrire votre activité principale dans la liste fournie. <br>
                            Cliquez sur le nom de la catégorie dans laquelle vous souhaitez ajouter un élément de liste...</p>
                        </div>
                        <a href="/create-e-commerce-product-type" class="btn btn-primary add-list"><i class="las la-plus mr-3"></i>Ajouter une categorie</a>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="table-responsive rounded mb-3">
                    <table class="data-table table mb-0 tbl-server-info">
                        <thead class="bg-white text-uppercase">
                            <tr class="ligth ligth-data">
                                <th>Libelle</th>
                                <th>Description</th>
                                <th>Categorie Parente</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody class="ligth-body">
                            <tr v-for="(stock,index) in stocks" :key="index">
                                <td>{{stock.libelle}}</td>
                                <td>{{stock.description}}</td>  
                                <td v-if="stock.parent">{{stock.parent.libelle}}</td>  
                                <td v-else></td> 
                                <td>
                                    <div class="d-flex align-items-center list-action">
                                        
                                        <router-link :to="'/show-e-commerce-product-type/'+stock.id" 
                                                    class="badge badge-info mr-2" data-toggle="tooltip" data-placement="top" 
                                                    title="Voir" data-original-title="View"><i class="fa fa-eye mr-0"></i>
                                        </router-link>
                                        <router-link :to="'/edit-e-commerce-product-type/'+stock.id" 
                                                    class="badge bg-success mr-2" data-toggle="tooltip" data-placement="top" 
                                                    title="Modifier" data-original-title="Edit"><i class="fa fa-edit mr-0"></i>
                                        </router-link>
                                        <button class="btn bg-warning mr-2" data-toggle="tooltip" data-placement="top" title="Supprimer" data-original-title="Delete"
                                            @click="destroy(stock.id)"><i class="fa fa-trash mr-0"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            <!-- Page end  -->
        </div>
        <!-- Modal Edit -->
        <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="true" 
                :is-full-page="fullPage"></loading>
            </div>
    </div>
</template>
<script>
import axios from "axios";
import {URL_COMMERCE_API} from '@/config'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
export default {
    components: {
            Loading
    },
    data() {
        return {
            stocks:{},
            isLoading: false,
            fullPage: true
        };
    },
    mounted(){
        this.productTypes()
    },
    methods:{
        productTypes() {
            this.isLoading =  true
            axios.get(URL_COMMERCE_API+'product-types')
            .then(response => {
                console.log(response.data)
                this.stocks = response.data
                this.isLoading =  false
            })
            .catch(error => {
                console.log(error)
                this.errored = true
                Swal.fire(
                        'Erreur!',
                        'Une erreur s\'est produite lors de la recuperation des données !',
                        'error'
                )
                this.isLoading =  false
            })
        },
        destroy(id) {
            let app = this
            Swal.fire({
                title: 'Voulez-vous supprimer cette categorie?',
                text: "cette action est irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, supprimer!'
            }).then((result) => {
            if (result.isConfirmed) {
                this.isLoading =  true
                axios.delete(URL_COMMERCE_API+'product-types/'+id)
                .then(response => {
                    app.productTypes()
                    console.log('ddd',response)
                    this.stocks = response.data
                    if (response.data.state) {
                        Swal.fire(
                            'Supprimé!',
                            'La categorie a été supprimée.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Attention!',
                             response.data.message,
                            'warning'
                        )
                    }
                    this.isLoading =  false
                    
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                    this.isLoading =  false
                })
                
            }
            })
            
        },
    }
}
</script>